.autoCompleteSelect__control {
  border-color: rgb(209 213 219);
}
.autoCompleteSelect__input {
  box-shadow: none !important;
}
.autoCompleteSelect__indicator-separator {
  display: none;
}

.autoCompleteSelect__control--is-focused {
  border-color: #4e2780 !important;
  box-shadow: 0 0 0 1px #4e2780 !important;
}

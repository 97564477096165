.radioInput[type='radio'] {
  width: 24px;
  height: 24px;
}

.radioInput[type='radio'] {
  border-color: transparent;
  background-image: unset !important;
  width: 18px;
  height: 18px;
  outline: 1px solid #4e2b7e;
  outline-offset: 3px;
}

.companyGallery .slick-prev {
  left: calc(100% - 4.5rem);
  top: -1.25rem;
  @apply z-50 h-5 w-5 text-themeBlack-dark hover:text-themeBlack-light;
}

.companyGallery .slick-next {
  left: calc(100% - 2.25rem);
  top: -1.25rem;
  @apply z-50 h-5 w-5 text-themeBlack-dark hover:text-themeBlack-light;
}
.companyMedia {
  width: 100%;
  height: 200px;
  @apply relative overflow-hidden rounded-lg;
}

.companyMedia > iframe {
  width: 100%;
  height: 200px;
}

.companyMediaThumbnail {
  width: 100%;
  height: 100px;
  cursor: pointer;
  @apply relative overflow-hidden rounded-lg;
}

.companyMediaThumbnail > iframe {
  width: 100%;
  height: 100px;
}

@media (min-width: 500px) {
  .companyMedia {
    height: 250px;
  }

  .companyMedia > iframe {
    height: 250px;
  }
}

@media (min-width: 600px) {
  .companyMedia {
    height: 400px;
  }

  .companyMedia > iframe {
    height: 400px;
  }
}
@media (min-width: 800px) {
  .companyMedia {
    height: 450px;
  }

  .companyMedia > iframe {
    height: 450px;
  }
}

@media (min-width: 1024px) {
  .companyMedia {
    height: 600px;
  }

  .companyMedia > iframe {
    height: 600px;
  }

  .companyMediaThumbnail > img {
    height: 100px;
  }

  .companyMediaThumbnail > iframe {
    height: 100px;
  }
}

@media (min-width: 1280px) {
  .companyMedia {
    height: 300px;
  }

  .companyMedia > iframe {
    height: 300px;
  }
}

@media (min-width: 1380px) {
  .companyMedia {
    height: 350px;
  }

  .companyMedia > iframe {
    height: 350px;
  }
}

@media (min-width: 1580px) {
  .companyMedia {
    height: 450px;
  }

  .companyMedia > iframe {
    height: 450px;
  }
}
